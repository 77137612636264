<template>
  <Spin fix :show="spinShow" >加载中...</Spin>
  <div class="articleList">
    <div class="articleItem" v-for="(item, index) in data" :key="index" @click="toInfo(item.id)">
      <div class="article">
        <div class="article-title">{{ item.title }}</div>
        <div class="info">
          <div class="article-auth">作者:<span v-for="(authItem, authKey) in item.adminInfo" :key="authKey">{{ authItem.admin_name }}</span></div>
          <div class="article-classification">所属分类:<span v-for="(classificationItem, classificationKey) in item.classification" :key="classificationKey">{{ classificationItem.name }}</span></div>
          <div class="article-time">创建时间:{{ formatDate(item.created_at) }}   修改时间:{{ formatDate(item.updated_at) }}</div>
        </div>
        <div class="article-cover">
          <img :src="item.cover" alt="">
        </div>
      </div>
      <div class="articleInfo">{{ toText(item.content) }}</div>
    </div>
  </div>
  <div class="loadMore">
    <Space direction="vertical" type="flex">
      <Button type="success" long>SUBMIT</Button>
    </Space>
  </div>
</template>

<script>
import contentRequest from "../../request/apiRequest/contentRequest";
import moment from "moment/moment";
export default {
  name: "contentList",
  data() {
    return {
      loading: true,
      data: [],
      id: null,
      spinShow: true,
      lastId: 0,
      isLast: false,
    }
  },
  mounted() {
    this.id = null;
    this.id = this.$route.params.id;
    this.getList();
  },
  methods: {
    getList() {
      contentRequest.List(this.id).then((res) => {
        if (res.status === 0) {
          this.data = res.data;
        }
        this.spinShow = false;
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    toText: function (value) {
      return value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
    },
    toInfo: function (id) {
      this.$router.push(`/content/info/${id}/0`);
    }
  },
  watch: {
    $route: {
      handler() {
        this.id = this.$route.params.id;
        this.spinShow = true;
        this.getList();
      }
    }
  }
}
</script>

<style scoped>
.articleList {
  width: 60%;
}
.articleItem{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
  box-sizing: border-box;
}
.article {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.article-title {
  font-size: 16px;
  font-weight: 500;
  color: #42b983;
  text-align: justify;
  word-wrap:break-word;
}
.article-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  height: 100%;
  margin-right: 10px;
}
.article-cover img {
  width: 100%;
}
.article-content, .article-time {
  font-size: 14px;
}
.article-time, .article-auth, .article-classification {
  color: #c5c5c5;
}

.info {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.articleInfo {
  margin-top: 10px;
  /* 第一步：弹性伸缩盒子模型展示 */
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-line-clamp: 3;
  /* 设置或检索伸缩盒子对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
  /* 第二部：多出的部分隐藏 */
  overflow: hidden;
  /* 第三步：加上省略号 */
  text-overflow: ellipsis;
}
.articleItem {
  cursor:pointer;
}
</style>
